



















import { Component } from 'vue-property-decorator'

import { ImageData } from '../../../contexts'
import { toImageProps } from '../../../front/shared/support'

import { AbstractModuleUi } from '../../abstract/ui'

import { ImageListModule, ResolvedListItem } from '../ImageList.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ImageListModuleUi>({
  name: 'ImageListModuleUi'
})
export class ImageListModuleUi extends AbstractModuleUi<ImageListModule> {
  public get hasItems (): boolean {
    return typeof this.content.items !== 'undefined' &&
      Array.isArray(this.content.items) && this.content.items.length > 0
  }

  public imageProps (item: ImageData) {
    return toImageProps(item)
  }

  /**
   * Check if backgroundColor exists and contains '#' at the beginning
   * @param element
   */
  public backgroundColor (element: ResolvedListItem): string {
    if (!element.backgroundColor) {
      return '#fff' /** default */
    }

    if (String(element.backgroundColor).charAt(0) === '#') {
      return element.backgroundColor
    } else {
      return `#${element.backgroundColor}`
    }
  }
}

export default ImageListModuleUi
